import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Tooltip } from "@mui/material";

import { ServiceLabel } from "../components";

import { getDateWithFormat } from "helpers/momentHelper";
import { formatNumber, getKmFromMeters } from "helpers/tourDataHelper";
import { PAYMENTS } from "constants/payments";
import { discardEarning } from "actions";
import ModalWindow from "components/ModalWindow";
import { Status } from "components/Payments";

import showIcon from "assets/images/payments/show.svg";

export const PaymentDetailsTable = ({ data, headerData }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const query = new URLSearchParams(useLocation().search);
  const queryStartDate = query.get(PAYMENTS.start_date);
  const queryEndDate = query.get(PAYMENTS.end_date);

  const { id } = useParams();

  const handleNavigate = (tourId) => {
    navigate(`/payments/${id}/tour-details/${tourId}?${query.toString()}`);
  };

  const openDiscardModal = (elementId) => {
    setSelectedId(elementId);
    setModalOpen(true);
  };

  const handleDiscard = () => {
    const params = {
      start_date: new Date(queryStartDate),
      end_date: new Date(queryEndDate),
    };
    dispatch(discardEarning({ id: selectedId, photographerId: id, params }));
  };

  return (
    <div className="payment_table">
      <ModalWindow
        open={modalOpen}
        setOpen={setModalOpen}
        title={PAYMENTS.discard}
        message={PAYMENTS.discard_message}
        submit={handleDiscard}
      />
      <table cellPadding="1" cellSpacing="1" className="table" bgcolor="#fff">
        <thead>
          <tr className="header_row">
            {headerData.map((element) => (
              <th className="header_element" key={element.id}>
                {element.name}
              </th>
            ))}
            <th className="header_element header_show_element"></th>
          </tr>
        </thead>
        <tbody>
          {data?.map((element) => (
            <tr className="table_column" key={element?.id}>
              <td className="table_element">
                {getDateWithFormat(element?.tour_date, "DD.MM.YY")}
              </td>
              <td className="table_element">
                {element?.address?.length > 22 ? (
                  <Tooltip title={element?.address}>
                    <span className="address_element">{element?.address}</span>
                  </Tooltip>
                ) : (
                  element?.address
                )}
              </td>
              <td className="table_element">
                <Status
                  status={element?.is_paid ? PAYMENTS.paid : PAYMENTS.unpaid}
                  isSuccess={element?.is_paid}
                />
              </td>
              <td className="table_element">
                {getKmFromMeters(element?.distance)}
              </td>
              <td className="table_element">{`$${formatNumber(
                element?.travel_cost
              )}`}</td>
              <td className="table_element">{element?.property_size}</td>
              <td className="table_element">
                <div className="service_labels">
                  {element?.package?.id && (
                    <ServiceLabel name={element?.package.title} />
                  )}
                  {element?.services?.map((elem) => (
                    <ServiceLabel name={elem.title} key={elem.id} />
                  ))}
                </div>
              </td>
              <td className="table_element">{`$${element?.custom_adjustment}`}</td>
              <td className="table_element">{`$${element?.service_cost}`}</td>
              <td className="table_element">{`$${formatNumber(
                element?.total_earning
              )}`}</td>
              <td className="table_element">
                <button
                  className="mark_as_paid_btn"
                  disabled={
                    element?.tour_appointment_status !== PAYMENTS.manual_done
                  }
                  onClick={() => openDiscardModal(element?.id)}
                >
                  {PAYMENTS.discard}
                </button>
              </td>
              <td className="table_element show_column">
                <img
                  src={showIcon}
                  alt="Show"
                  className="show_icon"
                  onClick={() => handleNavigate(element?.tour_id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
