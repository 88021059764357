import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { MarkAsPaid, ModalWindow, Status } from "components";
import { markAsPaidAction } from "actions/payments";
import { PAYMENTS } from "constants/payments";
import { formatNumber } from "helpers/tourDataHelper";
import { getDateWithFormat } from "helpers/momentHelper";
import { emptyPaymentDetailsData } from "features/paymnetsSlice";

import showIcon from "assets/images/payments/show.svg";

export const PaymentsTable = ({ data, headerData, startDate, endDate }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const query = new URLSearchParams(useLocation().search);

  const openMarkPaidModal = (elementId) => {
    setSelectedId(elementId);
    setModalOpen(true);
  };

  const handleMarkAsPaid = () => {
    const formatStartDate = getDateWithFormat(startDate, "YYYY-MM-DD");
    const formatEndDate = getDateWithFormat(endDate, "YYYY-MM-DD");
    dispatch(
      markAsPaidAction({
        id: selectedId,
        data: { start_date: formatStartDate, end_date: formatEndDate },
      })
    );
  };

  const handleNavigate = (id) => {
    dispatch(emptyPaymentDetailsData());

    if (!query.get(PAYMENTS.start_date)) {
      query.set(PAYMENTS.start_date, startDate.toISOString());
      query.set(PAYMENTS.end_date, endDate.toISOString());
    }

    navigate(`${id}?${query.toString()}`);
  };

  return (
    <div className="payment_table">
      <ModalWindow
        open={modalOpen}
        setOpen={setModalOpen}
        title={PAYMENTS.mark_as_paid}
        message={PAYMENTS.mark_as_paid_message}
        submit={handleMarkAsPaid}
      />
      <table cellPadding="1" cellSpacing="1" className="table" bgcolor="#fff">
        <thead>
          <tr className="header_row">
            {headerData.map((element) => (
              <th className="header_element" key={element.id}>
                {element.name}
              </th>
            ))}
            <th className="header_element header_show_element"></th>
          </tr>
        </thead>
        <tbody>
          {data?.map((element) => (
            <tr className="table_column" key={element?.id}>
              <td className="table_element">{element?.name}</td>
              <td className="table_element">{`$${formatNumber(
                element?.total_earnings
              )}`}</td>

              <td className="table_element">
                <Status
                  status={element?.is_paid ? PAYMENTS.paid : PAYMENTS.unpaid}
                  isSuccess={element?.is_paid}
                />
              </td>
              <td className="table_element">
                <MarkAsPaid
                  disabled={element?.is_paid}
                  handleAction={() => openMarkPaidModal(element.id)}
                />
              </td>
              <td className="table_element show_column">
                <img
                  src={showIcon}
                  alt="Show"
                  className="show_icon"
                  onClick={() => handleNavigate(element?.id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
