import { useLocation, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";

import { getDateWithFormat } from "helpers/momentHelper";
import { PAYMENTS } from "constants/payments";

import "react-datepicker/dist/react-datepicker.css";

import calendarIcon from "assets/images/payments/calendar.svg";

export const CustomDatePicker = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}) => {
  const shownStart = getDateWithFormat(startDate);
  const shownEnd = getDateWithFormat(endDate);

  const query = new URLSearchParams(useLocation().search);

  const navigate = useNavigate();

  const handleChange = ([newStartDate, newEndDate]) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    if (newEndDate && newStartDate) {
      query.set(PAYMENTS.start_date, newStartDate.toISOString());
      query.set(PAYMENTS.end_date, newEndDate.toISOString());
      navigate(`?${query.toString()}`);
    }
  };

  return (
    <div>
      <DatePicker
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        onChange={handleChange}
        selectsRange
        showFullMonthYearPicker
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        maxDate={new Date()}
        customInput={
          <button className="calendar_button">
            <img src={calendarIcon} alt="Calendar" className="calendar_icon" />
            <div className="shown_date">{`${shownStart} - ${shownEnd}`}</div>
          </button>
        }
      />
    </div>
  );
};
