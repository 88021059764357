import { useSelector } from "react-redux";

import { PAYMENTS } from "constants/payments";

const imageBase = process.env.REACT_APP_API_IMAGE_URL;

export const Services = () => {
  const data = useSelector((state) => state.payments.tourDetails);

  return (
    <div className="tour_services">
      <p className="section_title">{PAYMENTS.services_and_packages}</p>
      {!!data?.package?.id && (
        <div className="package_section">
          <div className="package">
            <p className="package_name">{data?.package?.title}</p>
            <p className="price">{`$${data?.package?.photographer_fee}`}</p>
          </div>
          <div className="package_services_section">
            {data?.package?.services?.map((service) => (
              <div key={service.id} className="package_service">
                <div className="service_info">
                  <img
                    src={`${imageBase}${service.icon}`}
                    alt="Service"
                    className="service_icon"
                  />
                  <p className="service_element">{service.title}</p>
                </div>
                <div className="service_line"></div>
              </div>
            ))}
          </div>
        </div>
      )}
      {!!data?.customize?.length && (
        <div className="package_section">
          <p className="package_name">{PAYMENTS.customize}</p>
          <div className="package_services_section">
            {data?.customize?.map((service) => (
              <div className="package_service" key={service?.id}>
                <div className="service_name_price">
                  <div className="service_info">
                    <p className="service_element">{service.title}</p>
                  </div>
                  <p className="price">{`$${service?.price}`}</p>
                </div>
                <div className="service_line"></div>
              </div>
            ))}
          </div>
        </div>
      )}
      {!!data?.additional_services?.length && (
        <div className="package_section">
          <p className="package_name">{PAYMENTS.additional_services}</p>
          <div className="package_services_section">
            {data?.additional_services?.map((service) => (
              <div className="package_service" key={service?.id}>
                <div className="service_name_price">
                  <div className="service_info">
                    <img
                      src={`${imageBase}${service.icon}`}
                      alt="Service"
                      className="service_icon"
                    />
                    <p className="service_element">{service.title}</p>
                  </div>
                  <p className="price">{`$${service?.photographer_fee}`}</p>
                </div>
                <div className="service_line"></div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
