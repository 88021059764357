import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";

import { getEarningDetails } from "actions";
import { DetailHeader, PaymentDetailsTable } from "components";
import { DETAILS_TABLE_HEADER, PAYMENTS } from "constants/payments";
import {
  formatEndDate,
  formatStartDate,
  getDateWithFormat,
} from "helpers/momentHelper";

export const PaymentDetails = () => {
  const data = useSelector((state) => state.payments.earningDetailsList);
  const loading = useSelector((state) => state.payments.loading);

  const dispatch = useDispatch();

  const { id } = useParams();

  const query = new URLSearchParams(useLocation().search);
  const queryStartDate = query.get(PAYMENTS.start_date);
  const queryEndDate = query.get(PAYMENTS.end_date);

  useEffect(() => {
    const formatedStartDate = getDateWithFormat(
      formatStartDate(new Date(queryStartDate)),
      "YYYY-MM-DD HH:mm"
    );
    const formatedEndDate = getDateWithFormat(
      formatEndDate(new Date(queryEndDate)),
      "YYYY-MM-DD HH:mm"
    );
    const params = {
      start_date: formatedStartDate,
      end_date: formatedEndDate,
    };
    if (id) dispatch(getEarningDetails({ id, params }));
  }, []);

  return (
    <div className="payment_details_page">
      <DetailHeader />
      {loading ? (
        <div className="loading_field">
          <ClipLoader size={50} />
        </div>
      ) : (
        <PaymentDetailsTable data={data} headerData={DETAILS_TABLE_HEADER} />
      )}
    </div>
  );
};
