import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { Label } from "../components";

import { getDateWithFormat } from "helpers/momentHelper";
import {
  GOOGLE_DRIVE_LINK,
  GOOGLE_THUMBNAIL_LINK,
  PAYMENTS,
} from "constants/payments";
import { formatNumber } from "helpers/tourDataHelper";

import arrowLeft from "assets/images/payments/leftArrow.svg";
import Logo from "assets/images/sidebarHeader/logo.svg";

export const DetailHeader = () => {
  const total = useSelector((state) => state.payments.earningDetailsTotal);
  const isPaidEarnings = useSelector((state) => state.payments.isPaidEarnings);
  const photographer = useSelector(
    (state) => state.payments.earningPhotographerData
  );

  const query = new URLSearchParams(useLocation().search);
  const queryStartDate = query.get(PAYMENTS.start_date);
  const queryEndDate = query.get(PAYMENTS.end_date);

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  const startDateFormat = getDateWithFormat(
    new Date(queryStartDate),
    "MMM DD/YY"
  );
  const endDateFormat = getDateWithFormat(new Date(queryEndDate), "MMM DD/YY");

  const extractFileId = (url) => {
    const match = url?.match(/id=([^&]+)/);
    return match ? match[1] : null;
  };

  const isGoogleDrive = photographer?.image?.includes(GOOGLE_DRIVE_LINK);

  return (
    <div className="details_header">
      <div className="header_left_side">
        <div className="left_side_top">
          <img
            src={arrowLeft}
            alt="Back"
            className="back_icon"
            onClick={handleBack}
          />
          <div className="date_user_name">
            <div className="date_section">{`${startDateFormat} - ${endDateFormat}`}</div>
            <div className="line_section"></div>
            <div className="name_section">{photographer?.name}</div>
          </div>
        </div>
        <div className="left_side_bottom">
          {photographer?.image && (
            <img
              src={
                photographer?.image
                  ? isGoogleDrive
                    ? `${GOOGLE_THUMBNAIL_LINK}${extractFileId(
                        photographer?.image
                      )}`
                    : photographer?.image
                  : Logo
              }
              alt="Avatar"
              className="avatar"
              width="100%"
            />
          )}
          <p className="user_name">{photographer?.name}</p>
        </div>
      </div>
      <div className="header_right_side">
        <Label
          status={isPaidEarnings ? PAYMENTS.paid : PAYMENTS.unpaid}
          title={PAYMENTS.total}
          total={`$${formatNumber(total)}`}
          customClass="details_label"
          isSuccess={isPaidEarnings}
        />
      </div>
    </div>
  );
};
