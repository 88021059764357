import { PATHNAME } from "constants/pathnames";

// Temporary Hide Appointments Menu
// import appointmentsIcon from "assets/images/sidebar-menu/user.svg";
import paymentsIcon from "assets/images/sidebar-menu/payouts.svg";

export const Menu = [
  // Temporary Hide Appointments Menu
  // {
  //   id: 1,
  //   name: "Appointments",
  //   path: PATHNAME.appointments,
  //   icon: <img src={appointmentsIcon} alt="Appointments" />,
  // },
  {
    id: 2,
    name: "Payments",
    path: PATHNAME.payments,
    icon: <img src={paymentsIcon} alt="Payments" />,
  },
];
