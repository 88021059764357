import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";

import { getTourDetails } from "actions/payments";
import { TourDetailHeader, TourDetailsPage } from "components";

export const TourDetails = () => {
  const loading = useSelector((state) => state.payments.loading);

  const dispatch = useDispatch();

  const { tourId } = useParams();

  useEffect(() => {
    if (tourId) dispatch(getTourDetails({ id: tourId }));
  }, [tourId]);

  return (
    <div className="payment_details_page">
      <TourDetailHeader />
      {loading ? (
        <div className="loading_field">
          <ClipLoader size={50} />
        </div>
      ) : (
        <TourDetailsPage />
      )}
    </div>
  );
};
