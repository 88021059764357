import React, { useRef } from "react";

import { CONSTANTS } from "constants/general";
import {
  BootstrapDialog,
  ConfirmButton,
  RefuseButton,
  BootstrapDialogActions,
  BootstrapDialogContent,
  StyledDialogTitle,
} from "./styledComponents";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import useOutsideClick from "hooks/useOutsideClick";

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <StyledDialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </StyledDialogTitle>
  );
}

const ModalWindow = ({
  open,
  setOpen,
  message,
  isQuestion = false,
  setIsConfirmed = () => {},
  submit,
  title,
}) => {
  const modalRef = useRef();

  const handleClose = () => {
    setOpen(false);
    setIsConfirmed(() => false);
  };

  useOutsideClick(modalRef, handleClose);

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        ref={modalRef}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {title || CONSTANTS.DELETE}
        </BootstrapDialogTitle>
        <BootstrapDialogContent dividers={isQuestion}>
          <Typography gutterBottom>{message}</Typography>
        </BootstrapDialogContent>
        <BootstrapDialogActions>
          <RefuseButton onClick={handleClose}>{CONSTANTS.CANCEL}</RefuseButton>
          <ConfirmButton onClick={submit}>{CONSTANTS.CONFIRM}</ConfirmButton>
        </BootstrapDialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default ModalWindow;
