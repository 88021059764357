import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { useLocation } from "react-router-dom";

import { getPayments } from "actions";
import { PaymentHeader, PaymentsTable } from "components";
import { PAYMENTS, TABLE_HEADER } from "constants/payments";
import {
  formatEndDate,
  formatStartDate,
  getDateWithFormat,
} from "helpers/momentHelper";

export const Payments = () => {
  const dispatch = useDispatch();

  const query = new URLSearchParams(useLocation().search);
  const queryStartDate = query.get(PAYMENTS.start_date);
  const queryEndDate = query.get(PAYMENTS.end_date);

  const fifteenDaysAgo = new Date();
  fifteenDaysAgo.setDate(fifteenDaysAgo.getDate() - 15);
  const [startDate, setStartDate] = useState(
    queryStartDate ? new Date(queryStartDate) : fifteenDaysAgo
  );
  const [endDate, setEndDate] = useState(
    queryEndDate ? new Date(queryEndDate) : new Date()
  );

  const photographers = useSelector((state) => state.payments.photographers);
  const loading = useSelector((state) => state.payments.loading);

  useEffect(() => {
    const formatedStartDate = getDateWithFormat(
      formatStartDate(startDate),
      "YYYY-MM-DD HH:mm"
    );
    const formatedEndDate = getDateWithFormat(
      formatEndDate(endDate),
      "YYYY-MM-DD HH:mm"
    );
    const params = { start_date: formatedStartDate, end_date: formatedEndDate };

    if (startDate && endDate) {
      dispatch(getPayments({ params }));
    }
  }, [startDate, endDate]);

  return (
    <div className="payments_page">
      <PaymentHeader
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
      />
      {loading ? (
        <div className="loading_field">
          <ClipLoader size={50} />
        </div>
      ) : (
        <PaymentsTable
          data={photographers}
          headerData={TABLE_HEADER}
          startDate={startDate}
          endDate={endDate}
        />
      )}
    </div>
  );
};
