import moment from "moment";

export const getDateWithFormat = (date, format = "DD.MM.YYYY") => {
  return moment(date).format(format);
};

export const formatStartDate = (date) => {
  return moment(date).format("YYYY-MM-DD 00:00");
};

export const formatEndDate = (date) => {
  return moment(date).format("YYYY-MM-DD 23:59");
};
