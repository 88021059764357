import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { TourInfo } from "./TourInfo";

import { getDateWithFormat } from "helpers/momentHelper";
import { PAYMENTS } from "constants/payments";

import arrowLeft from "assets/images/payments/leftArrow.svg";
import userIcon from "assets/images/payments/user.svg";
import carIcon from "assets/images/payments/car.svg";
import dateTimeIcon from "assets/images/payments/dateTime.svg";
import { getHoursFromSeconds, getKmFromMeters } from "helpers/tourDataHelper";

export const TourDetailHeader = () => {
  const data = useSelector((state) => state.payments.tourDetails);

  const navigate = useNavigate();

  const query = new URLSearchParams(useLocation().search);
  const queryStartDate = query.get(PAYMENTS.start_date);
  const queryEndDate = query.get(PAYMENTS.end_date);

  const handleBack = () => {
    navigate(-1);
  };

  const startDateFormat = getDateWithFormat(
    new Date(queryStartDate),
    "MMM DD/YY"
  );
  const endDateFormat = getDateWithFormat(new Date(queryEndDate), "MMM DD/YY");

  return (
    <div className="tour_details_header">
      <div className="header_left_side">
        <div className="left_side_top">
          <img
            src={arrowLeft}
            alt="Back"
            className="back_icon"
            onClick={handleBack}
          />
          <div className="date_user_name">
            <div className="date_section">{`${startDateFormat} - ${endDateFormat}`}</div>
            <div className="line_section"></div>
            <div className="name_section name_section_details">
              {data?.client_name}
            </div>
            <div className="line_section"></div>
            <div className="tour_section">{data?.address}</div>
          </div>
        </div>
        <div className="left_side_bottom">
          <div className="tour_section">
            <p className="tour_name">{data?.address}</p>
          </div>
          <div className="tour_details">
            <TourInfo
              icon={userIcon}
              title={data?.client_name}
              info={data?.client_phone}
              isPhone
            />
            <div className="info_line"></div>
            <TourInfo
              icon={dateTimeIcon}
              title={PAYMENTS.date_time}
              info={getDateWithFormat(data?.date, "DD.MM.YY HH:mmA")}
            />
            <div className="info_line"></div>
            <TourInfo
              icon={carIcon}
              title={PAYMENTS.driving_distance}
              info={`${getHoursFromSeconds(
                data?.expected_driving_duration
              )}, ${getKmFromMeters(data?.expected_driving_distance)}km`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
