export const CONSTANTS = {
  IMAGES_FIELD: "images",
  REQUIRED: "Required",
  DELETE: "delete",
  SUCCESS: "success",
  TITLE: "Title",
  NAME: "Name",
  ENTER_TITLE: "Enter title",
  IMAGE: "Image",
  ENTER_IMAGE: "Enter image",
  ENTER_NAME: "Enter name",
  EMAIL: "Email",
  CHANGE_PASSWORD: "Change password",
  LOG_OUT: "Log Out",
  OLD_PASSWORD: "Old password",
  PASSWORD: "Password",
  CURRENT_PASSWORD: "Current password",
  NEW_PASSWORD: "New password",
  CONFIRM_PASSWORD: "Confirm password",
  CANCEL: "Cancel",
  CONFIRM: "Confirm",
  SAVE: "Save",
  PASSWORD_CHANGE_SUCCESS: "Password changed successfully",
  EMAIL_VALIDATION_MESSAGE: "Please enter valid email",
  INVALID_PASSWORD_MESSAGE: "Invalid password",
  BACK_BUTTON: "Back",
  MAX_QUESTIONS_COUNT_MESSAGE:
    "The maximum question limit for the diagnostic quiz is set at 20.",
  MAX_QUESTIONS_COUNT: 20,
  CREATE: "Create",
  EDIT: "Edit",
  REMOVE: "Remove",
  VIMEO_SETTINGS: "Vimeo Settings",
  VIMEO_CLIENT_ID: "Vimeo Client Id",
  ENTER_VIMEO_CLIENT_ID: "Enter Vimeo Client Id",
  VIMEO_CLIENT_SECRET: "Vimeo Client Secret",
  ENTER_VIMEO_CLIENT_SECRET: "Enter Vimeo Client Secret",
  VIMEO_ACCESS_TOKEN: "Vimeo Access Token",
  ENTER_VIMEO_ACCESS_TOKEN: "Enter Vimeo Access Token",
  DELETE_VIMEO_SETTINGS: "Are you sure. You want to delete vimeo settings?",
  DELETE_TESTIMONIAL: "Are you sure. You want to delete testimonial?",
  COMMENT: "Comment",
  ENTER_COMMENT: "Enter Comment",
  STARS: "Stars",
  ENTER_STARS: "Enter Stars",
  WRITE_NOTES: "Write a Note",
  SEND: "Send",
};

export const HELPER_TEXT =
  "If you want a word or phrase to be highlighted in color, please put it in {{ }}";

export const LOGIN_PAGE = {
  welcome: "Welcome to Property Vision Admin!",
  sign_in_text: "Sign in to your account to get started",
  sign_in: "Sign in",
  email_address: "Email address",
  password: "Password",
  remember_me: "Remember me",
};

export const ACCESS_TOKEN = "access_token";

export const TABLE = {
  subject: "Subject",
  private_subject: "Private Subject",
  zero_value: 0,
  changed_page_count: 1,
  rtl: "rtl",
};

export const PASSWORD_MESSAGES = {
  minCharacter: "Password must be at least 8 characters long",
  lowerCase: "Password must contain at least one lowercase letter",
  upperCase: "Password must contain at least one uppercase letter",
  number: "Password must contain at least one number",
  password_match: "Passwords must match",
};

export const TABLE_PER_PAGE_COUNT = 15;
export const TABLE_MIN_PAGE = 1;
export const SEARCH_KEY = "search";

export const LOGOUT_MODAL = {
  title: "Log Out",
  message: "Are you sure you want to log out?",
};
